import React, { Fragment, useContext } from 'react';
import Media from 'react-media';

import { themeContext } from '../../stateProvider';

import './widgetOnpageNavigation.scss';

import OnpageNavlink from './onpageNavlink';
import { useTranslation } from 'react-i18next';

export const MobileOnpageNavigationButton = ({ toggleOpen, isOpen }) => {
    const { state: themeState } = useContext(themeContext);


    return (
        <div className='mobileOnpageNavigationButton'>
            <button
                className={`mobileOnpageNavigationButton__button mobileOnpageNavigationButton__button--${themeState.theme}`}
                onClick={() => toggleOpen()}
            >
                <svg 
                    className={`
                        mobileOnpageNavigationButton__button__svg 
                        mobileOnpageNavigationButton__button__svg--${themeState.theme} 
                        ${
                            isOpen
                            ?
                            'mobileOnpageNavigationButton__button__svg--open'
                            :
                            'mobileOnpageNavigationButton__button__svg--closed'
                        }
                    `} 
                    width="2em" 
                    height="2em" 
                    version="1.1" 
                    viewBox="0 0 16 16" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m5.6562 9.8535a0.5 0.5 0 0 0-0.36133 0.14648l-3.3301 3.3301v-2.2949a0.5 0.5 0 0 0-0.5-0.5 0.5 0.5 0 0 0-0.5 0.5v3.5a0.5 0.5 0 0 0 0.5 0.5h3.5a0.5 0.5 0 0 0 0-1h-2.291l3.3281-3.3281a0.5 0.5 0 0 0 0-0.70703 0.5 0.5 0 0 0-0.3457-0.14648z" />
                    <path fillRule="evenodd" d="m11.076 0.98047a0.5 0.5 0 0 0 0 1h2.2383l-3.3125 3.3125a0.50028 0.50028 0 1 0 0.70703 0.70703l3.3672-3.3672v2.3477a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-3.5a0.5 0.5 0 0 0-0.5-0.5h-3.5z" />
                    <path fillRule="evenodd" d="m10.328 9.8359a0.5 0.5 0 0 0-0.34766 0.14648 0.5 0.5 0 0 0 0 0.70898l3.3105 3.3086h-2.291a0.5 0.5 0 0 0-0.5 0.5 0.5 0.5 0 0 0 0.5 0.5h3.5a0.5 0.5 0 0 0 0.5-0.5v-3.5a0.5 0.5 0 0 0-1 0v2.293l-3.3125-3.3105a0.5 0.5 0 0 0-0.35938-0.14648z" />
                    <path fillRule="evenodd" d="m1.5 1a0.5 0.5 0 0 0-0.5 0.5v3.5a0.5 0.5 0 0 0 1 0v-2.291l3.3086 3.3105a0.50028 0.50028 0 1 0 0.70898-0.70703l-3.3105-3.3125h2.293a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-3.5z" />
                </svg>
            </button>
        </div>
    )
}

const OnpageNavigation = ({ links, isOpen, toggleOpen, articleName }) => {
    const { t, i18n } = useTranslation();
    const { state: themeState } = useContext(themeContext);


    return (
        <>
            <div className={`onpageNavigationMobile onpageNavigationMobile--${themeState.theme} ${isOpen ? 'onpageNavigationMobile--open' : 'onpageNavigationMobile--closed'}`}>
                <h2
                    className='onpageNavigationMobile__contents'
                >
                    {t("widget.onPageNavigation.contents")}
                </h2>
                <div>
                    <OnpageNavlink
                        onClick={() => {
                            window.scrollTo(0, 0);
                            toggleOpen();
                        }}
                        href='#'
                        id={articleName}
                    >
                        {articleName.toUpperCase()}
                    </OnpageNavlink>
                    {
                        links && links.map((link, i) => (
                            <OnpageNavlink
                                onClick={() => toggleOpen()}
                                key={link.hash}
                                href={link.hash}
                                id={link.title}
                            >
                                {link.title.toUpperCase()}
                            </OnpageNavlink>
                        ))
                    }
                    <hr />
                </div>
            </div>
            <div className={`onpageNavigationDesktop onpageNavigationDesktop--${themeState.theme}`}>
                <h2
                    className='onpageNavigationDesktop__contents'
                >
                    {t("widget.onPageNavigation.contents")}
                </h2>
                <OnpageNavlink
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    href='#'
                    id={articleName}
                >
                    {articleName.toUpperCase()}
                </OnpageNavlink>
                {
                    links && links.map((link, index) => (
                        <OnpageNavlink
                            onClick={() => { }}
                            key={link.hash}
                            href={link.hash}
                            id={link.title}
                        >
                            {link.title}
                        </OnpageNavlink>
                    ))
                }
            </div>
        </>
    )
}

export default OnpageNavigation;


/* 
const OnpageNavigation = ({ links, isOpen, toggleOpen, articleName }) => {
    const { t, i18n } = useTranslation();
    const { state: themeState } = useContext(themeContext);


    return (
        <Media queries={{
            mobile: "(max-width: 800px)",
            desktop: "(min-width: 800px)"
        }}>
            {matches => (
                <Fragment>
                    {
                        matches.mobile
                            ?
                            <div className={`onpageNavigationMobile onpageNavigationMobile--${themeState.theme} ${isOpen ? 'onpageNavigationMobile--open' : 'onpageNavigationMobile--closed'}`}>
                                <h2
                                    className='onpageNavigationMobile__contents'
                                >
                                    {t("widget.onPageNavigation.contents")}
                                </h2>
                                <div>
                                    <OnpageNavlink
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            toggleOpen();
                                        }}
                                        href='#'
                                        id={articleName}
                                    >
                                        {articleName}
                                    </OnpageNavlink>
                                {
                                    links && links.map((link, i) => (
                                        <OnpageNavlink
                                            onClick={() => toggleOpen()}
                                            key={link.hash}
                                            href={link.hash}
                                            id={link.title}
                                        >
                                            {link.title.toUpperCase()}
                                        </OnpageNavlink>
                                    ))
                                }
                                <hr/>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        matches.desktop
                            ?
                            <div className={`onpageNavigationDesktop onpageNavigationDesktop--${themeState.theme}`}>
                                <h2
                                    className='onpageNavigationDesktop__contents'
                                >
                                    {t("widget.onPageNavigation.contents")}
                                </h2>
                                <OnpageNavlink
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                        href='#'
                                        id={articleName}
                                    >
                                        {articleName.toUpperCase()}
                                </OnpageNavlink>
                                {
                                    links && links.map((link, index) => (
                                        <OnpageNavlink
                                            onClick={() => {}}
                                            key={link.hash}
                                            href={link.hash}
                                            id={link.title}
                                        >
                                            {link.title}
                                        </OnpageNavlink>
                                    ))
                                }
                            </div>
                            :
                            null
                    }
                </Fragment>
            )}
        </Media>
    )
}


*/