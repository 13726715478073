import React from 'react';
import {
    TwitterShareButton,
    TwitterIcon,
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    WeiboShareButton,
    WeiboIcon,
    RedditShareButton,
    RedditShareCount,
    RedditIcon
} from 'react-share';

import './widgetSocialShare.scss';


const SocialShare = ({ title, url, twitterHandle, tags }) => {

    return (
        <div className='socialShareContainer'>
          <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                <TwitterIcon  size={40} round={true} />
          </TwitterShareButton>
          <FacebookShareButton url={url} title={title}>
              <FacebookIcon size={40} round={true} />
              <FacebookShareCount url={url} />
          </FacebookShareButton>
          <TelegramShareButton url={url} title={title}>
              <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
          <WeiboShareButton url={url} title={title}>
              <WeiboIcon size={40} round={true} />
          </WeiboShareButton>
          <RedditShareButton url={url} title={title}>
              <RedditIcon size={40} round={true} />
              <RedditShareCount url={url}/>
          </RedditShareButton>
        </div>
    )
}

export default SocialShare;