import React, { useEffect, useState, useRef, createRef, useContext } from "react"
import { useLocation } from '@reach/router';
import firebase from "gatsby-plugin-firebase";
import { Link } from "gatsby";
import Media from 'react-media'
import Layout from "../components/layout"
import SEO from "../components/seo"

import './blogpostTemplate.scss';

import OnpageNavigation, { MobileOnpageNavigationButton } from "../components/widgetOnpageNavigation";
import HeartBtn from "../components/widgetHeartBtn";


import { useTranslation } from "react-i18next";
import { themeContext } from "../../stateProvider";
import ViewCounter from "../components/widgetViewCounter";
import CommentSection from "../components/widgetComment";
import SocialShare from "../components/widgetSocialShare";

const addAlreadyLiked = (postFileName) => {
  try {
    const likedPostsSerialized = localStorage.getItem('likedPosts');

    if (likedPostsSerialized === null) {
      let likedPostsArray = [];
      likedPostsArray.push(postFileName);
      localStorage.setItem('likedPosts', JSON.stringify(likedPostsArray));
    } else {
      let likedPostsArray = JSON.parse(likedPostsSerialized);
      if (likedPostsArray.indexOf(postFileName) !== -1) return 
      likedPostsArray.push(postFileName);
      localStorage.setItem('likedPosts', JSON.stringify(likedPostsArray));
    }
  } catch (err) {
    console.log(err);
  }
}; 

const checkAlreadyLiked = (postFilename) => {
  try {
    const likedPostsSerialized = localStorage.getItem('likedPosts');

    if (likedPostsSerialized === null) {
      return false;
    } else {
      let likedPostsArray = JSON.parse(likedPostsSerialized);
      if (likedPostsArray.indexOf(postFilename) !== -1) {
        return true
      } else {
        return false
      }
    }
  } catch {
    return undefined
  }
};


export default ({ data, pageContext }) => {
  let location = useLocation();
  const { state: themeState } = useContext(themeContext);
  const { t, i18n } = useTranslation()

  const [hasMounted, setHasMounted] = useState(false);

  const [onpageLinks, setOnpageLinks] = useState([]);

  const [possibleEntries, setPossibleEntries] = useState([]);

  const [isPagenavigationOpen, setPagenavigationOpen] = useState(false);
  
  const post = data.markdownRemark;

  // Comments
  const [isCommentSectionShow, setIsCommentSectionShown] = useState(false);

  // Heart button state
  const [likeCounter, setLikeCounter] = useState('');
  const [isBeingLiked, setIsBeingLiked] = useState(false);
  const [isAlreadyLiked, setIsAlreadyLiked] = useState(checkAlreadyLiked(post.frontmatter.filename));

  // View counter state
  const [viewCounter, setViewCounter] = useState('');
  const [isViewCounterUpdating, setIsViewCounterUpdating] = useState(false);

    // Handlers
  // Navigation handler
  const toggleOpen = () => {
    setPagenavigationOpen(current => current ? false : true)
  }

  // Like handlers
  const handleLikePost = async () => {
    try {
      let docRef = await firebase.firestore().collection('blog-posts').doc(post.frontmatter.filename);

      let doc = await docRef.get();

      setIsBeingLiked(true);

      let oldLikes = await doc.data().likes;

      await docRef.update({
        likes: oldLikes + 1
      });

      let updatedLikes= await (await docRef.get()).data().likes

      setLikeCounter(likes => updatedLikes);

      addAlreadyLiked(post.frontmatter.filename)
      setIsAlreadyLiked(true);
      setIsBeingLiked(false);
    } catch (err) {
      setIsBeingLiked(false);
      console.log(err);
    }
  }

  // View counter handlers
  const handleUpdateViewCounter = async () => {
    try {
      setIsViewCounterUpdating(true);
      let docRef = await firebase.firestore().collection('blog-posts').doc(post.frontmatter.filename);

      let doc = await docRef.get();

      let oldViews = await doc.data().views;

      await docRef.update({
        views: oldViews + 1
      });

      let updatedViews = await (await docRef.get()).data().views

      setViewCounter(views => updatedViews)

      setIsViewCounterUpdating(false);
    } catch (err) {
      setIsViewCounterUpdating(false);
      console.log(err);
    }
  }

    // Effects
  // Anchors and navigation
  useEffect(() => {
    if (hasMounted) {
      let anchors = document.querySelectorAll('.hover-anchor');

      if (possibleEntries.length === 0) {
        let worArray = Array.from(anchors).map(entry => {
          return `section__${entry.ariaLabel.slice(0, (entry.ariaLabel.length - 10)).replace(/ /g,'-')}`
        })
        setPossibleEntries([...worArray])

      }
  
      if (anchors && anchors.length > 0) {
        let links = Array.from(anchors).map(a => {
          return { hash: a.hash, title: a.ariaLabel.slice(0, (a.ariaLabel.length - 10)) }
        })
        setOnpageLinks(links)
      }
  
      return () => {
        setOnpageLinks([]);
      }
    }
  }, [hasMounted]);

  useEffect(() => {

    let enteredArray = [];

    const observer = new window.IntersectionObserver((entries) => {
  
        entries.forEach((entry, index) => {
          try {
          let id = entry.target.className.substr(9)

          if (entry.isIntersecting) {
  
            if (enteredArray.indexOf(id) === -1) enteredArray.push(id)
  
            if (enteredArray.indexOf(id) === enteredArray.length - 1) {
              document
                .querySelectorAll(`.onpageNavigation__navlinkOf${id}`)
                .forEach(e => e.classList.add('onpageNavigation__navlink--active'))
  
              if (enteredArray.length > 1) {
                for (let i = 0; i < enteredArray.indexOf(id); i++) {
                  document
                  .querySelectorAll(`.onpageNavigation__navlinkOf${enteredArray[i]}`)
                  .forEach(e => e.classList.remove('onpageNavigation__navlink--active'))
                }
              }
            }

          } else {          
            if (enteredArray.indexOf(id) === enteredArray.length - 1) {
              document
              .querySelectorAll(`.onpageNavigation__navlinkOf${enteredArray[enteredArray.indexOf(id)]}`)
              .forEach(e => e.classList.remove('onpageNavigation__navlink--active'))
  
              enteredArray.splice(enteredArray.indexOf(id), 1)
  
              if (enteredArray[enteredArray.length - 1]) {
                document
                .querySelectorAll(`.onpageNavigation__navlinkOf${enteredArray[enteredArray.length - 1]}`)
                .forEach(e => e.classList.add('onpageNavigation__navlink--active'))
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      });   
    });

    if (possibleEntries.length > 0) {
      let sections = [];

      possibleEntries.forEach(e => {
        if (document.querySelector(`.${e}`)) {
          sections.push(document.querySelector(`.${e}`))
        }
      })

      sections.forEach(a => observer.observe(a))
    }

    return () => {
      observer.disconnect();
    }
  }, [possibleEntries]);

  // Fetch likes and views
  useEffect(() => {
    const fetchLikesAndViews = async () => {
      try {
        setIsBeingLiked(true);
        setIsViewCounterUpdating(true);

        let docRef = await firebase.firestore().collection('blog-posts').doc(post.frontmatter.filename);

        let doc = await docRef.get();

        if (!doc.exists) {         
          await firebase.firestore().collection('blog-posts').doc(post.frontmatter.filename).set({
            views: 1,
            likes: 0
          });

          let docRef = await firebase.firestore().collection('blog-posts').doc(post.frontmatter.filename);
  
          let doc = await docRef.get();
  
          let data = await doc.data();
  
          setLikeCounter(likes => data.likes);
          setViewCounter(views => data.views);
        } else {
  
          let doc = await docRef.get();
  
          let data = await doc.data();
  
          setLikeCounter(likes => data.likes);
          await handleUpdateViewCounter();
        }
        setIsBeingLiked(false);
        setIsViewCounterUpdating(false);
      } catch (err) {
        setIsBeingLiked(false);
        setIsViewCounterUpdating(false);
        console.log(err);
      }
    }

    fetchLikesAndViews();
  }, []);

  // Theme transitions
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Layout>
      <SEO
        title={`${post.frontmatter.title} | ${post.frontmatter.postType === 'tutorial-post' ? `onClick! Tutorials` : `Tech & beyond Feature articles &amp; Opinions`}`}
        blogPostTitle={post.frontmatter.filename}
        description={post.frontmatter.description ? post.frontmatter.description : ''}
      />
      <div className='blogpostPage page' >
        <div className={`blogpostPage__header blogpostPage__header--${themeState.theme}`}>
          <div
            className={`blogpostPage__header__linksDiv blogpostPage__header__linksDiv--${themeState.theme}`}
          >
            <Link
              className={`blogpostPage__header__linksDiv__link`}
              to="/blog/"
            >
              {t("blogpost.header.blog")}
            </Link>
            <svg 
              width="1em" 
              height="1em" 
              viewBox="0 0 16 16" 
              className={`blogpostPage__header__linksDiv__chevronSvg`}
              fill="currentColor" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                fillRule="evenodd" 
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <Link
              className={`blogpostPage__header__linksDiv__link`}
              to={post.frontmatter.postType === 'tutorial-post' ? `/blog/onclick-tutorials/` : `/blog/tech-and-beyond/`}
            >
              {post.frontmatter.postType === 'tutorial-post' ? `onClick!` : `Tech & beyond`}
            </Link>
            <svg 
              width="1em" 
              height="1em" 
              viewBox="0 0 16 16" 
              className={`blogpostPage__header__linksDiv__chevronSvg`}
              fill="currentColor" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                fillRule="evenodd" 
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            {
              post.frontmatter.tags && post.frontmatter.tags.map(tag => (
            <Link
              key={tag}
              className={`blogpostPage__header__linksDiv__link`}
              to={`/blog/${post.frontmatter.postType === 'tutorial-post' ? `onclick-tutorials/` : `tech-and-beyond/`}`}
              state={{recievedTag: tag}}
            >
              {tag}
            </Link>
              ))
            }
          </div>
          <h1 className='blogpostPage__header__heading'>
            {post.frontmatter.title}
          </h1>
          <div className="blogpostPage__date">
          {new Date(post.frontmatter.date).toLocaleDateString(i18n.language)}
          </div>
        </div>
        {
        !hasMounted
        ?
        <div className='blogpostPage__spinner'>
          <div></div><div></div><div></div><div></div>
        </div>
        :
        <div
          className='blogpostPage__body'

          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        }
        <div
          className='blogpostPage__footer'
        >
          <ViewCounter
            views={viewCounter}
          />
          <SocialShare
            title={post.frontmatter.title}
            tags={post.frontmatter.tags}
            url={location.href}
            twitterHandle={'DmitryJima'}
          />
          <div
            className='blogpostPage__footer__lastupdatedDiv'
          >
            <h3>
              {t("blogpost.footer.lastUpdated")}
            </h3>
            <div>
              {new Date(post.frontmatter.lastUpdated).toLocaleString(i18n.language)}
            </div>
          </div>
          <HeartBtn
            numberProp={likeCounter}
            isLoading={isBeingLiked}
            isAlreadyLiked={isAlreadyLiked}
            onClick={handleLikePost}
          />
          <div className={`blogpostPage__footer__commentSection ${isCommentSectionShow ? '' : ''}`}>
            <button
              className={`blogpostPage__footer__commentSection__shownCommentsBtn blogpostPage__footer__commentSection__shownCommentsBtn--${themeState.theme}`}
              onClick={() => setIsCommentSectionShown(isCommentSectionShow ? false : true)}
            >
              <h3>
                {
                  isCommentSectionShow
                  ?
                  t("blogpost.footer.commentSectionHide")
                  :
                  t("blogpost.footer.commentSectionShow")
                }
              </h3>
            </button>
            {
              isCommentSectionShow
              ?
              <CommentSection />
              :
              null
            }
          </div>
          <div
            className='blogpostPage__footer__furtherReading'
          >
            {
            pageContext && pageContext.next
            ?
            <div
              className='blogpostPage__footer__furtherReading__next'
            >
              <h3>
                {t("blogpost.footer.prev")}
              </h3>
              <div>
                {pageContext.next.node.frontmatter.title}
              </div>
              <Link
                to={pageContext.next.node.frontmatter.path}
              >
                {t("blogpost.footer.read")}
              </Link>
            </div>
            :
            null
            }
            {
            pageContext && pageContext.prev
            ?
            <div
              className='blogpostPage__footer__furtherReading__prev'
            >
              <h3>
                {t("blogpost.footer.next")}
              </h3>
              <div>
                {pageContext.prev.node.frontmatter.title}
              </div>
              <Link
                to={pageContext.prev.node.frontmatter.path}
              >
                {t("blogpost.footer.read")}
              </Link>
            </div>
            :
            null
            }
          </div>
        </div>
      </div>
      {
        onpageLinks && onpageLinks.length > 0
          ?
          <>
            <OnpageNavigation
              links={onpageLinks}
              isOpen={isPagenavigationOpen}
              toggleOpen={toggleOpen}
              articleName={post.frontmatter.title}
            />
            <Media query="(max-width: 800px)" render={() =>
              (
                <MobileOnpageNavigationButton toggleOpen={toggleOpen} isOpen={isPagenavigationOpen} />
              )}
            />
          </>
          :
          null
      }
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        postType
        tags
        lastUpdated
        date
        filename
      }
    }
  }
`
