import React from 'react';
import { useTranslation } from 'react-i18next';

import './widgetHeartBtn.scss';

const HeartBtn = ({ isLoading, isAlreadyLiked, numberProp, onClick }) => {
    const { t, i18n } = useTranslation()

    return (
        <div
            className='heartBtnDiv'
            title={isAlreadyLiked ? t("blogpost.footer.alreadyLiked") : t("blogpost.footer.smashHeartBtn")}
        >
            <button
                className={`heartBtnDiv__btn ${isLoading ? 'heartBtnDiv__btn--animated' : ''}`}
                disabled={
                    isAlreadyLiked
                    ?
                    true
                    :
                            (
                                isLoading
                                ?
                                true
                                :
                                false
                            )
                }
                onClick={() => onClick()}
            >
                <svg className={`heartBtnDiv__btn__svg`} width="4em" height="4em" version="1.1" viewBox="0 0 210 210" xmlns="http://www.w3.org/2000/svg">
                    <g className={`heartBtnDiv__btn__svg__wrapperG`} transform="translate(-239.89 -144.18)" strokeOpacity=".2" strokeWidth=".1">
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup1" transform="translate(-3e-5,-5e-5)">
                            <path d="m338.78 325.94-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#6d0d52" />
                            <path d="m338.85 300.83-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#a90778" />
                            <path d="m331.5 288.29 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#cb047a" />
                            <path d="m309.79 275.67 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#a70878" />
                            <path d="m309.71 250.56 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f24925" />
                            <path d="m288 237.94 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f24925" />
                            <path d="m287.93 212.82 7.1898-12.536-7.2619-12.578-14.452-0.0416-7.1898 12.536 7.2619 12.578z" fill="#e92330" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup2" transform="translate(.37775 -.04183)">
                            <path d="m338.92 275.71-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#cb047a" />
                            <path d="m360.57 288.21-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#770c5a" />
                            <path d="m331.43 263.18 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f82e83" />
                            <path d="m331.36 238.06 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f8a516" />
                            <path d="m309.57 200.33 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f24925" />
                            <path d="m309.64 225.44 7.1898-12.536-7.2619-12.578-14.452-0.0416-7.1898 12.536 7.2619 12.578z" fill="#e92330" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup3" transform="translate(-4e-5,-4e-5)">
                            <path d="m339 250.6-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#f82e83" />
                            <path d="m360.64 263.09-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#cb047a" />
                            <path d="m382.28 275.59-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#6d3797" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup4">
                            <path d="m360.71 237.98-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#a70878" />
                            <path d="m382.35 250.47-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup5">
                            <path d="m382.42 225.36-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#1d0360" />
                            <path d="m404.06 237.85-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__innerRowGroup6">
                            <path d="m382.5 200.24-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#e92330" />
                            <path d="m404.14 212.74-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#0e2398" />
                        </g>
                        <g className="heartBtnDiv__btn__svg__wrapperG__outerFrameGroup" transform="translate(0,6e-5)">
                            <path d="m338.71 351.06-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#6d3797" />
                            <path d="m360.42 338.44-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#0e2398" />
                            <path d="m339.07 225.48-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#f34925" fillOpacity=".97255" />
                            <path d="m360.78 212.86-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                            <path d="m360.85 187.75-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#f8a516" />
                            <path d="m382.57 175.13-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#f24925" />
                            <path d="m404.21 187.63-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#ff8438" />
                            <path d="m425.85 200.12-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                            <path d="m425.71 250.35-7.1898-12.536 7.2619-12.578 14.452-0.0416 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                            <path d="m425.78 225.23-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#253189" />
                            <path d="m360.49 313.32-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#1d0360" />
                            <path d="m382.21 300.7-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#3b7edd" />
                            <path d="m403.92 288.08-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#371f8a" />
                            <path d="m403.99 262.97-7.1898-12.536 7.2619-12.578 14.452-0.0415 7.1898 12.536-7.2619 12.578z" fill="#0a198f" />
                            <path d="m331.64 338.52 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f82e83" />
                            <path d="m331.28 212.95 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f82e83" />
                            <path d="m331.21 187.83 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#fc5c4c" />
                            <path d="m309.5 175.21 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#ff8438" />
                            <path d="m287.86 187.71 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f24925" />
                            <path d="m266.22 200.2 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#ff8338" fillOpacity=".9451" />
                            <path d="m266.36 250.43 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#f8a516" />
                            <path d="m266.29 225.32 7.1898-12.536-7.2619-12.578-14.452-0.0416-7.1898 12.536 7.2619 12.578z" fill="#f26722" />
                            <path d="m331.57 313.4 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#770c5a" />
                            <path d="m309.86 300.79 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#a90778" />
                            <path d="m288.15 288.17 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#cb047a" />
                            <path d="m288.07 263.05 7.1898-12.536-7.2619-12.578-14.452-0.0415-7.1898 12.536 7.2619 12.578z" fill="#ff8438" />
                        </g>
                    </g>
                </svg>
                {
                numberProp && numberProp > 0
                ?
                <div className={`heartBtnDiv__btn__likeCounter`}>
                    {numberProp}
                </div>
                :
                null
                }
            </button>
        </div>
    )
}

export default HeartBtn;