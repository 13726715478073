import React from 'react';
import { useTranslation } from 'react-i18next';

import './widgetViewCounter.scss';

const ViewCounter = ({ views, isLoading }) => {
    const { t, i18n } = useTranslation()

    return (
        <div className="viewCounter" title={t("blogpost.footer.views")}>
            <div className="viewCounter__container">
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="viewCounter__container__svg" fill="var(--mainColorBlue--thin)" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
            <span className="viewCounter__container__viewsDiv">
                { !isLoading ? views : '-'}
            </span>
            </div>
        </div>
    )
}

export default ViewCounter;