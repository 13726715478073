import React from 'react';

import './onpageNavlink.scss';

const OnpageNavlink = ({ href, children, onClick, id, }) => {
    let ID = id ? id.replace(/ /g,'-') : '';

    return (
        <a 
            onClick={onClick} 
            href={href} 
            className={`onpageNavigation__navlink onpageNavigation__navlinkOf${ID}`}
        >
            {children}
        </a>
    )
}

export default OnpageNavlink;
