import React, { useState, useRef, useEffect, useContext, Suspense } from 'react';
import { themeContext } from '../../stateProvider';
import { useStaticQuery, graphql } from 'gatsby'

import './widgetComment.scss';

const CommentSection = () => {
    const { state: themeState } = useContext(themeContext);
    const [isLoading, setIsLoading] = useState(true);
    const commentBox = useRef(null);

    useEffect(() => {
      const scriptEl = document.createElement('script')
      scriptEl.async = true
      scriptEl.src = 'https://utteranc.es/client.js'
      scriptEl.setAttribute('repo', 'dmitryjima/zdcreatech-blog-comments')
      scriptEl.setAttribute('issue-term', 'og:title')
      scriptEl.setAttribute('id', 'utterances')
      scriptEl.setAttribute('theme', themeState.theme === 'dark' ? "github-dark" : 'github-light')
      scriptEl.setAttribute('crossorigin', 'anonymous')

      if (commentBox && commentBox.current) {
        commentBox.current.appendChild(scriptEl);

        scriptEl.addEventListener('load', () => {              
            commentBox.current.children[1] && commentBox.current.children[1].children[0] && commentBox.current.children[1].children[0].addEventListener('load', () => {
                setIsLoading(false)
              })
          })

      } else {
        console.log(`Error adding utterances comments on: ${commentBox}`)
      }
    }, []);

    return (
        <div ref={commentBox} className="comments">
            {
                isLoading
                ?
                <div className="comments__spinnerContainer">
                    <div className="comments__spinnerContainer__spinner"><div></div><div></div><div></div><div></div></div>
                </div>
                :
                null
            }
        </div>  
    )
}

export default CommentSection;

